// import React from 'react';

const GLOBAL_CONST = {
  STEP0: 'step0',
  STEP1: 'step1',
  STEP2: 'step2',
  STEP3: 'step3',
  STEP4: 'step4',
  ISDELETED: 'isdeleted',
  ISUPLOADED: 'isuploaded',
  STATUS: 'status',
  TYPEID: 'TypeID',
  NAME: 'Name',
  PROPOSALID: 'ProposalID',
  FILENAME: 'FileName',
  FILE: 'File',
  INDEX: 'Index',
  FILEOBJ: 'fileObj',
  EXPLANATION: 'Explanation',
  SPONSORSHIPBENEFITS: 'SponsorshipBenefits',
  ATTACHMENTS: 'Attachments',
  SAVEPROPOSALSTEP3: 'saveproposalstep3',
  SAVEPROPOSALSTEP1: 'saveproposalstep1',
  SAVEPROPOSALSTEP2: 'saveproposalstep2',
  ATTACHMENTID: 'AttachmentID',
  EmulateForm: 'emulate-form',
  CHANGEFORM: 'change-form',
  RESETFORM: 'reset-form',
  RESETPASSWORD: 'reset-password',
  LOCATIONADDFORM: 'location-add-form',
  LOGINFORM: 'login-form',
  SIGNUPFORM: 'signup-form',
  SPONSORFORM: 'sponsors-form',
  SPONSORSHIPTYPE: 'SponsorshipType',
  GEOGRAPHICLOCATIONID: 'GeographicLocationID',
  GEOGRAPHICLOCATIONTITLE: 'GeographicLocationTitle',
  CREATEPROPOSAL: 'createproposal',
  HOMESTEP1: '/home/step1/',
  HOMESTEP2: '/home/step2/',
  HOMESTEP4: '/home/step4/',
  HOMESTEP3: '/home/step3/',
  TECHHELPFORM: 'tech-help-form',
  DATEFROM: 'DateFrom',
  DATETO: 'DateTo',
  DATETIMEFORMATWS: 'YYYY-MM-DDTHH:mm:ss',
  DISPLAYDATEFORMAT: 'MM/DD/YYYY',
  INDEXWS: 'index',
  ERROR_FETCHING_PROPOSALS: 'ERROR_FETCHING_PROPOSALS',
  BLOB_NAME: 'BlobName',
  OTP: 'otp-form',
  ERROR_MSG_ACCOUNT_EXISTS: 'An account with this email address already exists.'
}

export default GLOBAL_CONST
